import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';

export interface IListExpense {
  id: number;
  location_id: number;
  pay_method: number;
  status: number;
  type: number;
  value: number;
  description: string;
  local: string;
  date: Date;
  date_pay: Date;
}

export interface IDetailExpense {
  id: number;
  location_id: number;
  pay_method: number;
  status: number;
  type: number;
  value: number;
  description: string;
  date: Date;
  date_pay: Date;
}

type TExpenseTotalCount = {
  data: IListExpense[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  status = 0,
  type = 0,
  all = false,
  id = 0
): Promise<TExpenseTotalCount | Error> => {
  try {
    // Inicializa a URL base
    let urlRelativa = `/expenses?page=${page}&limit=10`;

    // Condicionalmente adiciona os parâmetros de filtro
    if (filter) {
      urlRelativa += `&filter=${filter}`;
    }

    if (status) {
      urlRelativa += `&status=${status}`;
    }

    if (type) {
      urlRelativa += `&type=${type}`;
    }

    if (all) {
      urlRelativa += '&all'; // Quando 'all' for true, desconsidera a paginação
    }

    if (id) {
      urlRelativa += `&id=${id}`;
    }
    const { data } = await Api().get(urlRelativa);

    if (data) {
      return {
        data: data.data, // Aqui você acessa o array de dados diretamente
        totalCount: data.total, // Pegando o total de registros da resposta
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailExpense | Error> => {
  try {
    const { data } = await Api().get(`/expenses/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailExpense, 'id'>
): Promise<number | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');
    console.log(storedUserID);

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
      date_pay:
        dados.date_pay instanceof Date
          ? dados.date_pay.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date_pay, // Caso a data já esteja formatada
    };
    const { data } = await Api().post('/expenses', dadosComUserID);

    if (data.expense) {
      return data.expense.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailExpense
): Promise<void | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');
    console.log(storedUserID);

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
      date_pay:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };
    await Api().put(`/expenses/${id}`, dadosComUserID);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/expenses/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const ExpensesService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
