import { useEffect, useState } from 'react';
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Environment } from '../../../../shared/environment';
import {
  IListTableLocationSell,
} from '../../../../shared/services/api/dashboars/DashboardsService';

interface ITableSellProps {
  isExternalLoading?: boolean;
  data?: IListTableLocationSell[];
  totalCount: number;
}

export const TableSell: React.FC<ITableSellProps> = ({
  isExternalLoading = false,
  data,
  totalCount,
}) => {
  const [rows, setRows] = useState<IListTableLocationSell[]>([]);

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ m: 1, width: 'auto' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Local</TableCell>
            <TableCell>Qtd.</TableCell>
            <TableCell>Pago</TableCell>
            <TableCell>Restante</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.local}</TableCell>
              <TableCell>{row.totalCountSell}</TableCell>
              <TableCell>
                {row.totalPayment == null
                  ? 'R$ 0,00'
                  : row.totalPayment.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
              </TableCell>
              <TableCell>
                {row.receivables == null
                  ? 'R$ 0,00'
                  : row.receivables.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
              </TableCell>
     
              <TableCell>
                {row.grossProfit == null
                  ? 'R$ 0,00'
                  : row.grossProfit.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {totalCount === 0 && !isExternalLoading && (
          <caption>{Environment.EMPTY_LISTING}</caption>
        )}

        <TableFooter>
          {isExternalLoading && (
            <TableRow>
              <TableCell colSpan={3}>
                <LinearProgress variant="indeterminate" />
              </TableCell>
            </TableRow>
          )}
          {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography>{totalCount}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
