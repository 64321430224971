import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';

export interface IListTableLocationSell {
  id: number;
  local: string;
  totalCountSell: number;
  totalPayment: number;
  totalExpense: number;
  grossProfit: number;
  netProfit: number;
  receivables: number;
}

export interface IDetailDashboard {
  id: number;
  exam_id: number;
  location_id: number;
  date: Date;
  dateDelivery: Date;
  image?: string;
  status: number;
}

type TDashboardTotalCount = {
  data: IListTableLocationSell[];
  totalCount: number;
};

const getlocationDashboard = async (
  page = 1,
  filter = {},
  id = 0
): Promise<TDashboardTotalCount | Error> => {
  try {
    
    const urlRelativa = `/dashboards/locations?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&id=${id}`;
    
    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailDashboard | Error> => {
  try {
    const { data } = await Api().get(`/dashboards/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

export const DashboardsService = {
  getlocationDashboard,
  getById,
};
