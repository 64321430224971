import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  IIllness,
  PatientsService,
} from '../../shared/services/api/patients/PatientsService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VDateField } from '../../shared/forms/VDateField';
import { AutoCompleteLocation } from '../components/AutoCompleteLocation';
import { Illness } from './components/Illness';
import { VImageField } from '../../shared/forms/VImageField';

interface IFormData {
  location_id: number;
  name: string;
  fone: string;
  address: string;
  illness: IIllness; // Altere para array de strings
  description: string;
  image?: string;
  date: Date;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  name: yup.string().required().min(3).max(70),
  fone: yup.string().required().min(8).max(30),
  address: yup.string().required().min(3).max(150),
  illness: yup
    .object()
    .shape({
      estrabismo: yup.boolean().required(),
      catarata: yup.boolean().required(),
      glaucoma: yup.boolean().required(),
      ceratocone: yup.boolean().required(),
      diabete: yup.boolean().required(),
    })
    .required(),
  description: yup.string().required().min(3).max(150),
  image: yup.string().optional().max(150),
  date: yup.date().required(),
  location_id: yup.number().integer().required(),
});

export const DetailPatients: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      PatientsService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/patients');
        } else {
          setName(result.name);
          formRef.current?.setData(result);
        }
      });
    } else {
      formRef.current?.setData({
        location_id: 0,
        name: '',
        fone: '',
        address: '',
        illness: {
          estrabismo: false,
          catarata: false,
          glaucoma: false,
          ceratocone: false,
          diabete: false,
        },
        description: '',
        image: '',
        date: new Date(), // Define uma data padrão
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'nova') {
          PatientsService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/patients');
              } else {
                navigate(`/patients/detail/${result}`);
              }
            }
          });
        } else {
          PatientsService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/patients');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        console.log('Erros de validação:', errors.inner); // Verifica o array completo de erros
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          console.log('Erro no campo:', error.path, 'Mensagem:', error.message); // Log para cada campo com erro
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      PatientsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/patients');
        }
      });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'New Patient' : name}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Nova"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/patients')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/patients/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid
            container
            direction="row"
            padding={2}
            spacing={2}
            justifyContent={'center'}
            alignContent={'center'}
          >
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6">Registro de Paciente</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <VImageField name="image" folder="patient" imageName={name} />
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container direction="column" spacing={2}>
                <Grid
                  container
                  item
                  direction="row"
                  spacing={2}
                  justifyContent={'space-between'}
                >
                  <Grid item xs={6} sm={7} md={7} lg={8}>
                    <AutoCompleteLocation isExternalLoading={isLoading} />
                  </Grid>

                  <Grid item xs={6} sm={5} md={5} lg={4}>
                    <VDateField name="date" label="Data" />
                  </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                  <Grid item sm={12} md={8}>
                    <VTextField
                      fullWidth
                      name="name"
                      label="Nome"
                      disabled={isLoading}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={8} md={4}>
                    <VTextField
                      fullWidth
                      name="fone"
                      label="Telefone"
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <Illness isExternalLoading={isLoading} />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    name="address"
                    label="Endereço"
                    disabled={isLoading}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    name="description"
                    label="Descrição"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
