import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { IDetailProduct } from '../products/ProductsService';
import { IDetailSell } from '../sells/SellsService';

export interface IListItemSell {
  id: number;
  sell_id: number;
  product_id: number;
  sell?: IDetailSell;
  product?: IDetailProduct;
  total: number;
  date: Date;
}

export interface IDetailItemSell {
  id: number;
  sell_id: number;
  product_id: number;
  sell?: IDetailSell;
  product?: IDetailProduct;
  total: number;
  date: Date;
}

type TItemSellTotalCount = {
  data: IListItemSell[];
  totalCount: number;
};

const getAll = async (
  sell_id: number
): Promise<IDetailItemSell[] | Error> => {
  try {
    const urlRelativa = `/itemSells?sell_id=${sell_id}`;

    const { data } = await Api().get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailItemSell | Error> => {
  try {
    const { data } = await Api().get(`/itemSells/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailItemSell, 'id'>
): Promise<number | Error> => {
  try {
    const dadosFormatado = {
      ...dados,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };

    const { data } = await Api().post('/itemSells', dadosFormatado);

    if (data && data.itemSell.id) {
      return data.itemSell.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailItemSell
): Promise<void | Error> => {
  try {
    await Api().put(`/itemSells/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/itemSells/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const ItemSellsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
