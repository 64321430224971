import { useEffect, useMemo, useState } from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  IListExpense,
  ExpensesService,
} from '../../shared/services/api/expenses/ExpensesService';
import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environment';
import { useDebounce } from '../../shared/hooks';
import dayjs from 'dayjs';

export const ListExpenses: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListExpense[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const type = useMemo(() => {
    return Number(searchParams.get('type') || '');
  }, [searchParams]);

  const status = useMemo(() => {
    return Number(searchParams.get('status') || '');
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      ExpensesService.getAll(page, search, status, type).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [search, page, status, type]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ExpensesService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows((oldRows) => [
            ...oldRows.filter((oldRow) => oldRow.id !== id),
          ]);
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo="Despesas"
      barraDeFerramentas={
        <ToolsListing
          mostrarInputBusca
          mostrarInputStatus
          mostrarInputType
          textoDaBusca={search}
          textoBotaoNovo="Nova"
          opcoesDeType={[
            { value: 1, label: 'Alimentação' },
            { value: 2, label: 'Deslocamento' },
            { value: 3, label: 'Estadia' },
            { value: 4, label: 'Funcionario' },
            { value: 5, label: 'Outros' },

           
          ]}
          opcoesDeStatus={[
            { value: 1, label: 'Inativo' },
            { value: 2, label: 'Ativo' },
            { value: 3, label: 'Alocado' },
          ]}
          aoClicarEmNovo={() => navigate('/expenses/detail/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams(
              {
                search: texto,
                status: searchParams.get('status') || '',
                type: searchParams.get('type') || '',
                page: '1',
              },
              { replace: true }
            )
          }
          aoMudarTextoDeType={(value) =>
            setSearchParams(
              {
                type: value.toString(),
                search: searchParams.get('search') || '',
                status: searchParams.get('status') || '',
                page: '1',
              },
              { replace: true }
            )
          }
          aoMudarTextoDeStatus={(value) =>
            setSearchParams(
              {
                status: value.toString(),
                search: searchParams.get('search') || '',
                type: searchParams.get('type') || '',
                page: '1',
              },
              { replace: true }
            )
          }
        />
      }
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Ações</TableCell>
              <TableCell>Local</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Pagamento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <IconButton size="small" onClick={() => handleDelete(row.id)}>
                    <Icon>delete</Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/expenses/detail/${row.id}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.local}</TableCell>
                <TableCell>{row.status === 1 ? 'Aberto' : row.status === 2 ? 'Pago': ''}</TableCell>
                <TableCell>
                  {row.type === 1
                    ? 'Alimentação'
                    : row.type === 2
                      ? 'Deslocamento'
                      : row.type === 3
                        ? 'Estadia'
                        : row.type === 4
                          ? 'Funcionario'
                          : row.type === 5
                            ? 'Outros'
                            : row.type}
                </TableCell>
                <TableCell>
                  {row.value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </TableCell>
                <TableCell>
                  {dayjs(row.date_pay).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.EMPTY_LISTING}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { search, page: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
