import axios from 'axios';
import { responseInterceptor, errorInterceptor } from './interceptors';
import { Environment } from '../../../environment';

/* // Função para obter o token CSRF e armazená-lo no localStorage
export const getCsrfToken = async () => {
  try {
    const response = await axios.get(
      `${Environment.URL_BASE}/sanctum/csrf-cookie`,
      {
        withCredentials: true, // Necessário para enviar e receber cookies
      }
    );

    if (response.status === 204 || response.status === 200) {
      // O token CSRF foi configurado
      console.log('CSRF token obtido com sucesso.', response);

      // Salvar o token CSRF no localStorage (se houver um cabeçalho com o token)
      // Extrair o token do cookie XSRF-TOKEN
      const csrfToken = document.cookie
        .split('; ')
        .find((row) => row.startsWith('XSRF-TOKEN='))
        ?.split('=')[1];

    }
    return response;
  } catch (error) {
    console.error('Erro ao obter o CSRF token:', error);
  }
}; */

export const Api = () => {
  const token = localStorage.getItem('APP_ACCESS_TOKEN') || '""';
  console.log('token', token);

  const api = axios.create({
    baseURL: `${Environment.URL_BASE}/api`, // URL base para as requisições à API
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${JSON.parse(token)}`, // Token de autenticação JWT
    },
  });

  api.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error)
  );

  return api;
};

/* export const Api = () => {
  const api = axios.create({
    baseURL: Environment.URL_BASE,
    headers: {
      authorization: `Bearer ${JSON.parse(localStorage.getItem('APP_ACCESS_TOKEN') || '""')}`
    }
  });

  api.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return api;
};
 */
