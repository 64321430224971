import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

interface IDegree {
  esf: number;
  cil: number;
  eix: number;
  dnp: number;
  alt: number;
}

interface IDetailPrec {
  ld?: IDegree;
  le?: IDegree;
  pe?: IDegree;
  pd?: IDegree;
}

// Corrigido: use `exam: IDetailPrec` como tipo do parâmetro
const PrescriptionTable: React.FC<{
  ld?: IDegree;
  le?: IDegree;
  pe?: IDegree;
  pd?: IDegree;
}> = ({ ld, le, pd, pe }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="prescription table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Esférico</TableCell>
            <TableCell align="center">Cilíndrico</TableCell>
            <TableCell align="center">Eixo</TableCell>
            <TableCell align="center">D.P.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" component="th" scope="row">
              <Typography variant="body2">Longe O.D.</Typography>
            </TableCell>
            <TableCell align="center">{ld?.esf ?? '--'}</TableCell>
            <TableCell align="center">{ld?.cil ?? '--'}</TableCell>
            <TableCell align="center">{ld?.eix ?? '--'}</TableCell>
            <TableCell align="center">{ld?.dnp ?? '--'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" component="th" scope="row">
              <Typography variant="body2">Longe O.E.</Typography>
            </TableCell>
            <TableCell align="center">{le?.esf ?? '--'}</TableCell>
            <TableCell align="center">{le?.cil ?? '--'}</TableCell>
            <TableCell align="center">{le?.eix ?? '--'}</TableCell>
            <TableCell align="center">{le?.dnp ?? '--'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" component="th" scope="row">
              <Typography variant="body2">Perto O.D.</Typography>
            </TableCell>
            <TableCell align="center">{pd?.esf ?? '--'}</TableCell>
            <TableCell align="center">{pd?.cil ?? '--'}</TableCell>
            <TableCell align="center">{pd?.eix ?? '--'}</TableCell>
            <TableCell align="center">{pd?.dnp ?? '--'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" component="th" scope="row">
              <Typography variant="body2">Perto O.E.</Typography>
            </TableCell>
            <TableCell align="center">{pe?.esf ?? '--'}</TableCell>
            <TableCell align="center">{pe?.cil ?? '--'}</TableCell>
            <TableCell align="center">{pe?.eix ?? '--'}</TableCell>
            <TableCell align="center">{pe?.dnp ?? '--'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrescriptionTable;
