import { AxiosError } from 'axios';
import { Api } from '../axios-config';

export const handleImageUpload = async (
  imageBlobUrl: string,
  folder: 'patient' | 'exam' | 'product' | 'sell' | 'user',
  chunkSize: number = 1024 * 1024 // Tamanho do chunk, 1MB por padrão
): Promise<string> => {
  try {
    // Fetch the blob from the URL
    const response = await fetch(imageBlobUrl);
    const blob = await response.blob(); // Obter o Blob

    // Criar um arquivo a partir do Blob
    const file = new File([blob], 'image.png', { type: blob.type });

    const totalChunks = Math.ceil(file.size / chunkSize); // Total de chunks
    const uploadId = Date.now(); // Pode ser usado como identificador único do upload
    const token = localStorage.getItem('APP_ACCESS_TOKEN') || '""';

    // Realizar o upload de cada chunk
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end); // Dividir o arquivo em pedaços

      const formData = new FormData();
      formData.append('chunk', chunk); // Adiciona o pedaço do arquivo (chunk)
      formData.append('folder', folder); // Pasta do upload
      formData.append('uploadId', String(uploadId)); // Identificador do upload
      formData.append('chunkIndex', String(chunkIndex)); // Índice do chunk
      formData.append('totalChunks', String(totalChunks)); // Total de chunks

      // Fazendo a requisição de upload para cada pedaço
      const response =  await Api().post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });

      console.log(`Chunk ${chunkIndex + 1}/${totalChunks} enviado com sucesso.`);
      
      if (chunkIndex + 1 === totalChunks) {
        const fileName = response.data.fileName; // Captura o nome do arquivo
        console.log('Arquivo enviado com sucesso:', fileName);
        return fileName; // Retorne o nome do arquivo
      }
    }

    console.log('Upload em pedaços concluído com sucesso.');

    // Retornar o nome do arquivo salvo gerado pelo backend (último pedaço deve ter essa informação)
    const finalResponse = await Api().post('/upload/complete', {
      uploadId,
      folder,
    });

    return finalResponse.data.fileName || 'Nome da imagem não disponível';
  } catch (error) {
    console.error('Erro ao enviar a imagem:', error);
    throw error; // Propaga o erro para tratamento externo
  }
};

export const getImage = async (
  ImageName: string,
  folder: 'patient' | 'exam' | 'product' | 'sell' | 'user'
) => {
  try {
    const response = await Api().get(`images/${folder}/${ImageName}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });
    const imageURL = URL.createObjectURL(response.data);
    return imageURL; // Define a URL como o valor do campo
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};
