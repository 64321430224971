import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AuthService } from '../services/api/auth/AuthService';

interface IAuthContextData {
  logout: () => void;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<string | void>;
}

const AuthContext = createContext({} as IAuthContextData);

const LOCAL_STORAGE_KEY__ACCESS_TOKEN = 'APP_ACCESS_TOKEN';
const LOCAL_STORAGE_USER_ID = 'USER_ID';
const LOCAL_STORAGE_USER_NAME = 'USER_NAME';
const LOCAL_STORAGE_USER_PRIV = 'USER_PRIV';
const LOCAL_STORAGE_USER_IMAGE = 'USER_IMAGE';

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>();

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
    if (token) {
      setAccessToken(JSON.parse(token));
    } else {
      setAccessToken(undefined);
    }
  }, []);

  const handleLogin = useCallback(async (email: string, password: string) => {
    const result = await AuthService.auth(email, password);
    console.log('result', result);
    if (typeof result === 'string') {
      return result; // Retorna a mensagem de erro
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEY__ACCESS_TOKEN,
        JSON.stringify(result.token)
      );
      localStorage.setItem(
        LOCAL_STORAGE_USER_ID,
        JSON.stringify(result.userID)
      );
      localStorage.setItem(
        LOCAL_STORAGE_USER_NAME,
        JSON.stringify(result.userName)
      );
      localStorage.setItem(
        LOCAL_STORAGE_USER_PRIV,
        JSON.stringify(result.userPriv)
      );
      localStorage.setItem(
        LOCAL_STORAGE_USER_IMAGE,
        JSON.stringify(result.userImage)
      );
      setAccessToken(result.token);
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    localStorage.removeItem(LOCAL_STORAGE_USER_NAME);
    localStorage.removeItem(LOCAL_STORAGE_USER_PRIV);
    localStorage.removeItem(LOCAL_STORAGE_USER_IMAGE);
    setAccessToken(undefined);
  }, []);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
