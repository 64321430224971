import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  LinearProgress,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  TableHead,
  Typography,
  Container,
  Box,
  TextField,
} from '@mui/material';
import { AutoCompleteProduct } from './components/AutoCompleteProduct';
import * as yup from 'yup';
import { ItemSellsService } from '../../../../shared/services/api/itemsells/ItemSellsService';
//import { LensTreatment } from '../../../components/LensTreatment';
//import { LensType } from '../../../components/LensType';
import { IDetailExam } from '../../../../shared/services/api/exams/ExamsService';
import { ProductLens } from './components/ProductLens';
import { useNavigate } from 'react-router-dom';

interface IProduct {
  id: number;
  description: string;
  type: number;
  image?: string;
  priceSell: number;
  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
}

type TItemSell = {
  product_id: number;
  description: string;
  priceSell: number;
  uniqueID: number;
};

export interface IProductFrame {
  product_id?: number;
  uniqueID?: number;
  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
}

interface IAddItemProps {
  isExternalLoading?: boolean;
  sell_id: number;
  total: number;
  onExam?: IDetailExam;
  onAddItem?: (addItem: boolean) => void;
  onTotalAddItem?: (total: number) => void; // Callback para o componente pai
}

interface IFormData {
  sell_id: number;
  product_id: number;
  total: number;
  date: Date;
}

interface ICalc {
  priceBuy: number;
  priceSell: number;
  total: number;
}
const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  sell_id: yup.number().integer().required(),
  product_id: yup.number().integer().required(),
  total: yup.number().required(),
  date: yup.date().required(),
});

export const AddItem: React.FC<IAddItemProps> = ({
  isExternalLoading = false,
  sell_id,
  onExam,
  total,
  onAddItem,
  onTotalAddItem,
}) => {
  const [selectedProductList, setSelectedProductList] = useState<TItemSell[]>(
    []
  );
  const [productFrame, setProductFrame] = useState<IProductFrame>();
  const [isLoading, setIsLoading] = useState(false);
  const [onRemove, setOnRemove] = useState(0);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [exam, setExam] = useState<IDetailExam | undefined>(onExam);
  const [priceSell, setPriceSell] = useState<number>(0);
  const [isProductLensComplete, setIsProductLensComplete] = useState(false);

  useEffect(() => {
    if (onExam) {
      setExam(onExam);
    }
  }, [onExam]);


  // useEffect para verificar quando todos os produtos estão prontos
  useEffect(() => {
    if (sell_id && isProductLensComplete) {
      if (selectedProductList.length > 0) {
        // Se a lista de produtos não for vazia, cria os itens e salva
        handleSave();
      } else {
        if (onAddItem) {
          onAddItem(true); // Passa o valor true para o onAddItem
        }
      }
    }
  }, [sell_id, selectedProductList, isProductLensComplete]);

  // Função para capturar o valor do campo priceSell
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceSell(Number(event.target.value)); // Converta o valor para número
  };

  const handleAddItem = () => {
    if (product) {
      if (onTotalAddItem) {
        onTotalAddItem(Number(priceSell));
      }
      const uniqueID = Date.now(); // Identificador único para o item
      setSelectedProductList((prevList) => [
        ...prevList,
        {
          product_id: product.id,
          description: product.description,
          priceSell,
          uniqueID, // Novo identificador único
        },
      ]);
      if (product.type === 2) {
        setProductFrame({
          product_id: product.id,
          uniqueID,
          vertical: product.vertical,
          horizontal: product.horizontal,
          bridge: product.bridge,
          largDiagonal: product.largDiagonal,
        });
      }
    }
  };

  const handleRemoveProduct = (uniqueID: number) => {
    setOnRemove(uniqueID);
    const removedItem = selectedProductList.find(
      (item) => item.uniqueID === uniqueID
    );

    if (removedItem) {
      const negativeValue = -Math.abs(Number(removedItem.priceSell)); // Converte o valor para negativo
      if (onTotalAddItem) {
        onTotalAddItem(negativeValue);
      }
    }
    setSelectedProductList((prevItems) =>
      prevItems.filter((item) => item.uniqueID !== uniqueID)
    );
  };

  // Função chamada quando os produtos no ProductLens são salvos
  const handleProductSaveComplete = (product_ids: number[]) => {
    if (product_ids.length > 0 && product_ids[0] !== 0) {
      const newProducts = product_ids.map((id) => ({
        product_id: id,
        description: `Produto Lens ID: ${id}`,
        priceSell: 0,
        uniqueID: Date.now(),
      }));

      setSelectedProductList((prevList) => [...prevList, ...newProducts]);
    }

    // Define que o ProductLens completou
    setIsProductLensComplete(true);
  };

  const createItemSellData = (): IFormData[] => {
    const date = new Date();
    return selectedProductList.map((product) => ({
      sell_id,
      product_id: product.product_id,
      total: product.priceSell,
      date,
    }));
  };

  const handleSave = () => {
    const itemSellData = createItemSellData();

    const validPromises = itemSellData.map((item) =>
      formValidationSchema.validate(item, { abortEarly: false })
    );

    Promise.all(validPromises)
      .then((validatedItems) => {
        setIsLoading(true);

        validatedItems.forEach((item) => {
          ItemSellsService.create(item).then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              console.log('Item salvo com sucesso:', result);
              setSelectedProductList([]);
            }
          });
        });
        if (onAddItem) {
          onAddItem(true); // Passa o valor true para o onAddItem
        }
      })
      .catch((errors: yup.ValidationError) => {
        console.log('Erros de validação:', errors.inner);
      });
  };

  const renderSelectedProducts = () => (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{ m: 1, width: 'auto' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={100}>Ações</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProductList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveProduct(item.uniqueID)}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>
                        {item.priceSell.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
  // Renderiza os totais de preço de venda e custo
  const renderTotals = () => (
    <Grid item xs={12}>
      <Typography variant="h6">
        Total Preço Venda:{' '}
        {total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Typography>
    </Grid>
  );

  return (
    <Container>
      <Paper variant="outlined">
        <Typography variant="h6" pl={2} gutterBottom>
          Itens à Venda
        </Typography>

        <Grid container>
          <Grid item xs={12} md={8}>
            <Paper variant="outlined">
              <Box p={2}>
                <Typography variant="subtitle2">Produto</Typography>
                <AutoCompleteProduct
                  onProductSelect={(product) => setProduct(product)}
                />
              </Box>
              <Box pl={2} pb={2}>
                <ProductLens
                  onExam={exam}
                  onFrame={productFrame}
                  onRemove={onRemove}
                  sell_id={sell_id}
                  isLoading={isLoading}
                  onProductSaveComplete={handleProductSaveComplete}
                />
              </Box>
              <Box pl={2} pb={2}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      type='number'
                      label="Preço Venda"
                      size="small"
                      disabled={isLoading}
                      onChange={handlePriceChange} // Captura a mudança no campo
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      disabled={isExternalLoading}
                      variant="contained"
                      color="primary"
                      onClick={handleAddItem}
                    >
                      Adicionar Produto
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8}>
            <Paper variant="outlined">
              <Grid container direction="row" spacing={2}>
                <Grid item xs={5}>
                  {renderSelectedProducts()}
                </Grid>
                <Grid item xs={3}>
                  {renderTotals()}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
