import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';
import { IDetailPatient, IListPatient } from '../patients/PatientsService';
import { IListLocation } from '../locations/LocationsService';

interface IDiagnosis {
  astigmatismo: boolean;
  hipermetropia: boolean;
  miopia: boolean;
  presbiopia: boolean;
}

interface IDegree {
  esf: number;
  cil: number;
  eix: number;
  dnp: number;
  alt: number;
}

export interface IListExam {
  id: number;
  date: Date;
  local: string;
  patient?: IListPatient;
  location?: IListLocation;
  description: string;
}

export interface IDetailExam {
  id: number;
  date: Date;
  description: string;
  diagnosis: IDiagnosis;
  doctor: string;
  image?: string;
  ld?: IDegree;
  le?: IDegree;
  lensType: number;
  location_id: number;
  patient_id: number;
  patient?: IDetailPatient;
  patientName?: string;
  patientImage?: string;
  pe?: IDegree;
  pd?: IDegree;
}
type TExamTotalCount = {
  data: IListExam[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  id = 0
): Promise<TExamTotalCount | Error> => {
  try {
    let urlRelativa = '';

    urlRelativa = `/exams?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&id=${id}`;
    const { data } = await Api().get(urlRelativa);
    console.log(data);
    if (data) {
      return {
        data: data.data, // Aqui você acessa o array de dados diretamente
        totalCount: data.total, // Pegando o total de registros da resposta
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailExam | Error> => {
  try {
    const { data } = await Api().get(`/exams/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailExam, 'id' | 'patientName'>
): Promise<number | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;

    if (dados.image && !dados.image.startsWith('http')) {
      imageUrl = await handleImageUpload(dados.image, 'exam');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };

    const { data } = await Api().post('/exams', dadosComUserID);

    if (data && data.exam.id) {
      return data.exam.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: Omit<IDetailExam, 'patientName'>
): Promise<void | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;
    console.log('dados.image', dados.image);
    if (dados.image && !dados.image.startsWith('http')) {
      imageUrl = await handleImageUpload(dados.image, 'exam');
      console.log('imageUrl', imageUrl);
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };

    await Api().put(`/exams/${id}`, dadosComUserID);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/exams/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const ExamsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
