import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  LinearProgress,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  Typography,
  Container,
  MenuItem,
} from '@mui/material';
import * as yup from 'yup';
import { VForm, VTextField } from '../../../../shared/forms';
import { useVForm } from '../../../../shared/forms';
import { PaymentsService } from '../../../../shared/services/api/payments/PaymentsService';
import { useNavigate } from 'react-router-dom';
import { VDateField } from '../../../../shared/forms/VDateField';

interface IPaymentsSellProps {
  isExternalLoading?: boolean;
  sell_id: number;
  total: number;
  remainder: number;
  valuePay: number;
  onValuePayItem?: (total: number) => void;
  onAddPayment?: (addPayment: boolean) => void;
}

interface IFormData {
  sell_id: number;
  date: Date;
  date_pay: Date;
  status: number;
  payment_method: number;
  installments: number;
  value: number;
  uniqueID?: number;
}

interface ICalc {
  installments: number;
  cost: number;
  entry: number;
  remaining: number;
  total: number;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  sell_id: yup.number().integer().required(),
  date: yup.date().required(),
  date_pay: yup.date().required(),
  status: yup.number().required(),
  installments: yup.number().required(),
  payment_method: yup.number().required(),
  value: yup.number().required(),
  uniqueID: yup.number().optional(),
});

export const PaymentSell: React.FC<IPaymentsSellProps> = ({
  isExternalLoading = false,
  sell_id,
  total,
  remainder,
  valuePay,
  onValuePayItem,
  onAddPayment,
}) => {
  const [selectedPaymentList, setSelectedPaymentList] = useState<IFormData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { formRef } = useVForm();

  const [totalPriceSell, setTotalPriceSell] = useState(0);

  useEffect(() => {
    if (sell_id && sell_id > 0) {
      if (selectedPaymentList.length > 0) {
        handleSave();
      } else {
        if (onAddPayment) {
          onAddPayment(true); // Passa o valor true para o onAddItem
        }
      }
    }
  }, [sell_id]);

  const updatesell_idForPayments = (): IFormData[] => {
    return selectedPaymentList.map((payment) => ({
      ...payment,
      sell_id: sell_id,
    }));
  };

  const handleSave = () => {
    const paymentData = updatesell_idForPayments();
    const validPromises = paymentData.map((item) => {
      const { uniqueID, ...itemWithoutUniqueID } = item;

      return formValidationSchema.validate(itemWithoutUniqueID, {
        abortEarly: false,
      });
    });
    Promise.all(validPromises)
      .then((validatedItems) => {
        setIsLoading(true);

        validatedItems.forEach((item) => {
          PaymentsService.create(item).then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              console.log('Pagamento salvo com sucesso:', result);
              setSelectedPaymentList([]);
              if (onAddPayment) {
                onAddPayment(true); // Passa o valor true para o onAddItem
              }
            }
          });
        });
      })
      .catch((errors: yup.ValidationError) => {
        console.log('Erros de validação:', errors.inner);
      });
  };

  const handleRemovePayment = (uniqueID: number) => {
    // Localiza o item removido antes de filtrá-lo
    const removedItem = selectedPaymentList.find(
      (item) => item.uniqueID === uniqueID
    );

    // Se o item existir, passa o valor negativo para a função
    if (removedItem) {
      const negativeValue = -Math.abs(Number(removedItem.value)); // Converte o valor para negativo
      if (onValuePayItem) {
        onValuePayItem(negativeValue);
      }
    }

    // Atualiza a lista, removendo o item pelo uniqueID
    setSelectedPaymentList((prevItems) =>
      prevItems.filter((item) => item.uniqueID !== uniqueID)
    );
  };

  const handleAddPayment = (combinedData: IFormData[]) => {
    setSelectedPaymentList((prevList) => [...prevList, ...combinedData]);
  };

  const add = () => {
    formRef.current?.submitForm();
  };

  const addPayment = (dados: IFormData) => {
    const paymentDetail: IFormData = {
      sell_id: 0,
      date: new Date(),
      date_pay: dados.date_pay,
      status: dados.status,
      installments: dados.installments,
      payment_method: dados.payment_method,
      value: Number(dados.value),
      uniqueID: new Date().getTime(), // Cria um uniqueID baseado no timestamp
    };
    if (onValuePayItem) {
      onValuePayItem(Number(dados.value));
    }
    handleAddPayment([paymentDetail]); // Adiciona o novo pagamento à lista
  };

  const renderSelectedPayments = () => (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{ m: 1, width: 'auto' }}
            >
              <Table size="small">
                <TableBody>
                  {selectedPaymentList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {item.payment_method === 1
                          ? 'Dinheiro'
                          : item.payment_method === 2
                            ? 'Pix'
                            : item.payment_method === 3
                              ? 'Cartão de Crédito'
                              : item.payment_method === 4
                                ? 'Cartão de Débito'
                                : item.payment_method === 5
                                  ? 'Boleto'
                                  : item.payment_method}
                        - R$
                        {item.value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleRemovePayment(
                              item.uniqueID !== undefined ? item.uniqueID : 0
                            )
                          }
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
  // Renderiza os totais de preço de venda e custo
  const renderTotals = () => (
    <Grid item xs={12}>
      <Typography variant="h6">
        Total Preço Venda:{' '}
        {totalPriceSell.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Typography>
    </Grid>
  );

  return (
    <VForm ref={formRef} onSubmit={addPayment}>
      <Container>
        <Paper variant="outlined">
          <Typography variant="h6" pl={2} gutterBottom>
            Pagamento
          </Typography>

          <Grid container>
            <Grid item xs={12} md={8}>
              <Paper variant="outlined">
                <Typography variant="h6" padding={2}>
                  Detalhes do Pagamento
                </Typography>
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={6} sm={4} md={4}>
                    <VDateField name="date_pay" label="Data da Venda" />
                  </Grid>
                </Grid>
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <VTextField
                      name="payment_method"
                      label="Metodo"
                      select
                      fullWidth
                      disabled={isLoading}
                    >
                      <MenuItem value={1}>Dinheiro</MenuItem>
                      <MenuItem value={2}>Pix</MenuItem>
                      <MenuItem value={3}>Cartão de Crédito</MenuItem>
                      <MenuItem value={4}>Cartão de Debito</MenuItem>
                      <MenuItem value={5}>Boleto</MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <VTextField
                      name="status"
                      label="Status"
                      select
                      fullWidth
                      disabled={isLoading}
                    >
                      <MenuItem value={1}>Pago</MenuItem>
                      <MenuItem value={0}>Pendente</MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={4} sm={3} md={2}>
                    <VTextField
                      name="installments"
                      label="Parcelas"
                      select
                      fullWidth
                      disabled={isLoading}
                      defaultValue={1}
                    >
                      <MenuItem value={1}>Á vista</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <VTextField
                      name="value"
                      label="Valor"
                      type="number"
                      fullWidth
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      disabled={isExternalLoading}
                      variant="contained"
                      color="primary"
                      onClick={add}
                    >
                      Receber
                    </Button>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignItems="center"
                    justifyContent={'center'}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">
                        Total :{' '}
                        {total.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6">
                        Pago :{' '}
                        {valuePay.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6">
                        Restante :{' '}
                        {remainder.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={8}>
              <Paper variant="outlined">
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    {renderSelectedPayments()}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </VForm>
  );
};
