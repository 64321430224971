import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

interface ILensProps {
  isExternalLoading?: boolean;
  initialData?: {
    esf: number;
    cil: number;
    eix: number;
    dnp: number;
    alt: number;
    add: number;
  };
  onLensChange: (lensData: any) => void;
}

export const Lens: React.FC<ILensProps> = ({
  isExternalLoading = false,
  initialData = { esf: 0, cil: 0, eix: 0, dnp: 0, alt: 0, add: 0 },
  onLensChange,
}) => {
  const [lens, setLens] = useState(initialData);

  // Atualiza o estado local se os dados iniciais mudarem
  useEffect(() => {
    setLens(initialData);
  }, [initialData]);

  useEffect(() => {
    onLensChange(lens); // Chama o callback sempre que os dados mudarem
  }, [lens, onLensChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLens((prev) => ({ ...prev, [name]: Number(value) || 0 }));
  };

  return (
    <Grid container item direction="row" spacing={2}>
      {isExternalLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        <>
          <Grid item xs={2}>
            <TextField
              label="Esf."
              name="esf"
              value={lens.esf}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Cil."
              name="cil"
              value={lens.cil}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Eixo"
              name="eix"
              value={lens.eix}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Add"
              name="add"
              value={lens.add}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              label="DNP"
              name="dnp"
              value={lens.dnp}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              label="ALT"
              name="alt"
              value={lens.alt}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
