import axios from 'axios';
import { Api } from '../axios-config';

interface IAuth {
  token: string;
  userID: number; // Certifique-se de que o tipo está correto
  userName: string;
  userPriv: number; // Alterado para number para coincidir com o JSON
  userImage: string | null; // A imagem pode ser nula
}

const auth = async (
  email: string,
  password: string
): Promise<IAuth | string> => {
  try {
    const response = await Api().post('/login', { email, password });
    console.log('response', response);

    // Verifique se a resposta foi bem-sucedida
    if (response.status === 201 && response.data.user) {
      const { user, token } = response.data;
      
      // Armazenar o token no localStorage
      localStorage.setItem('APP_ACCESS_TOKEN', JSON.stringify(token));
      console.log('Token armazenado:', localStorage.getItem('APP_ACCESS_TOKEN'));

      return {
        token: token,
        userID: user.id,
        userName: user.name,
        userPriv: user.priv,
        userImage: user.image,
      };
    }

    return 'Erro no login: resposta inválida.';
  } catch (error) {
    console.error('Erro ao realizar login:', error);
    // Verifique se a resposta do erro é acessível
    if (axios.isAxiosError(error) && error.response) {
      console.error('Erro de resposta:', error.response.data);
      return error.response.data.message || 'Erro no login.';
    }

    return 'Erro no login: ocorreu um erro inesperado.';
  }
};

export const AuthService = {
  auth,
};
