import { useEffect, useState } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useField } from '@unform/core';
import { VCheckbox } from '../../../shared/forms/VCheckbox';
import { IIllness } from '../../../shared/services/api/patients/PatientsService';

interface IIllnessProps {
  isExternalLoading?: boolean;
}

export const Illness: React.FC<IIllnessProps> = ({
  isExternalLoading = false,
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField('illness');

  const [illness, setIllness] = useState<IIllness>(
    defaultValue || {
      estrabismo: false,
      catarata: false,
      glaucoma: false,
      ceratocone: false,
      diabete: false,
    }
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => illness,
      setValue: (_, newIllness) => setIllness(newIllness),
    });
  }, [registerField, fieldName, illness]);

  const handleCheckboxChange = (name: keyof IIllness, checked: boolean) => {
    const updatedIllness = { ...illness, [name]: checked };
    setIllness(updatedIllness);
    clearError(); // Limpa o erro ao modificar qualquer checkbox
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Grid item>
          <VCheckbox
            name="illness.estrabismo"
            label="Estrabismo"
            checked={illness.estrabismo}
            onChange={(_, checked) =>
              handleCheckboxChange('estrabismo', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="illness.catarata"
            label="Catarata"
            checked={illness.catarata}
            onChange={(_, checked) => handleCheckboxChange('catarata', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="illness.diabete"
            label="Diabete"
            checked={illness.diabete}
            onChange={(_, checked) => handleCheckboxChange('diabete', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="illness.ceratocone"
            label="Ceratocone"
            checked={illness.ceratocone}
            onChange={(_, checked) =>
              handleCheckboxChange('ceratocone', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="illness.glaucoma"
            label="Glaucoma"
            checked={illness.glaucoma}
            onChange={(_, checked) => handleCheckboxChange('glaucoma', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        {isExternalLoading && (
          <Grid item>
            <CircularProgress size={28} />
          </Grid>
        )}
      </Grid>

      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
    </>
  );
};
