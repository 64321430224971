import { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Icon,
  LinearProgress,
} from '@mui/material';
import { ItemSellsService } from '../../../shared/services/api/itemsells/ItemSellsService';

interface IItemSellProps {
  sell_id?: number; // O sell_id será passado como prop
  onTotalItem?: (total: number) => void; // Callback para o componente pai
}

export const ItemSell: React.FC<IItemSellProps> = ({ sell_id, onTotalItem }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sell_id) {
      setIsLoading(true);
      ItemSellsService.getAll(sell_id).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          const items = result; 
          setRows(result);

          // Calcula a soma dos valores do campo total
          const totalSum = items.reduce(
            (acc, item) => acc + (item.total || 0),
            0
          );

          // Faça algo com o valor total, por exemplo, salvar no estado
          if (onTotalItem) {
            onTotalItem(totalSum);
          }
        }
      });
    }
  }, [sell_id]);

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      ItemSellsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          const removedItem = rows.find(
            (row) => row.id === id
          );
          if (removedItem) {
            const negativeValue = -Math.abs(Number(removedItem.total)); // Converte o valor para negativo
            if (onTotalItem) {
              onTotalItem(negativeValue);
            }
          }
          setRows((oldRows) => oldRows.filter((row) => row.id !== id));
          alert('Registro apagado com sucesso!');
        }
      });
    }
  };

  return (
    <Grid container direction="column" spacing={2} p={3}>
      <Grid item xs={12}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(row.id)}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {row.product.type === 1
                        ? 'Lente'
                        : row.product.type === 2
                          ? 'Armação'
                          : 'Outro'}
                    </TableCell>
                    <TableCell>{row.product.description}</TableCell>
                    <TableCell>
                      {row.total.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
