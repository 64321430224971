import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';

interface IFrameProps {
  isExternalLoading?: boolean;
  initialData?: {
    vertical: number;
    horizontal: number;
    bridge: number;
    largDiagonal: number;
  };
  onFrameChange: (frameData: any) => void;
}

export const Frame: React.FC<IFrameProps> = ({
  isExternalLoading = false,
  initialData = { vertical: 0, horizontal: 0, bridge: 0, largDiagonal: 0 },
  onFrameChange,
}) => {
  const [frame, setFrame] = useState(initialData);

  // Atualiza o estado local se os dados iniciais mudarem
  useEffect(() => {
    setFrame(initialData);
  }, [initialData]);

  useEffect(() => {
    onFrameChange(frame); // Chama o callback sempre que os dados mudarem
  }, [frame, onFrameChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFrame((prev) => ({ ...prev, [name]: Number(value) || 0 }));
  };

  return (
    <Grid container item direction="row" spacing={2}>
      {isExternalLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        <>
          <Grid item xs={3}>
            <TextField
              label="Vertical"
              name="vertical"
              value={frame.vertical}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Horizontal"
              name="horizontal"
              value={frame.horizontal}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Bridge"
              name="bridge"
              value={frame.bridge}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Largura Diagonal"
              name="largDiagonal"
              value={frame.largDiagonal}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
