import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { ExamsService } from '../../../shared/services/api/exams/ExamsService';
import { useDebounce } from '../../../shared/hooks';
import { useField } from '@unform/core';

type TAutoCompleteExamOption = {
  id: number;
  label: string;
};

interface IAutoCompleteExamProps {
  isExternalLoading?: boolean;
  onExamSelect: (id: number) => void;
}
export const AutoCompleteExam: React.FC<IAutoCompleteExamProps> = ({
  isExternalLoading = false,
  onExamSelect,
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField('exam_id');
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<number | undefined>(
    defaultValue
  );

  const [opcoes, setOpcoes] = useState<TAutoCompleteExamOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => {
        setSelectedId(newSelectedId);
        if (newSelectedId !== undefined) {
          onExamSelect(newSelectedId); 
        }
      },
    });

    if (defaultValue !== undefined) {
      setSelectedId(defaultValue);
      onExamSelect(defaultValue); 
    }
  }, [registerField, fieldName, selectedId, onExamSelect]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      ExamsService.getAll(0, busca, selectedId).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setOpcoes(
            result.data.map((exam) => ({
              id: exam.id,
              label: exam.patient?.name === undefined ? '-' : exam.patient?.name,
            }))
          );
        }
      });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find((opcao) => opcao.id === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Autocomplete
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      style={{ width: '100%' }}
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => {
        setSelectedId(newValue?.id);
        setBusca('');
        clearError();
        if (newValue?.id !== undefined) {
          onExamSelect(newValue.id); // Chama a função apenas se o ID for definido
        }
      }}
      popupIcon={
        isExternalLoading || isLoading ? (
          <CircularProgress size={28} />
        ) : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Exames"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};
