import {
  Box,
  Button,
  Grid,
  Icon,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from '@mui/material';

import { Environment } from '../../environment';

interface IToolsListingProps {
  textoDaBusca?: string;
  textoDaType?: number;
  textoDaStatus?: number;
  mostrarInputBusca?: boolean;
  mostrarInputType?: boolean;
  mostrarInputStatus?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDeType?: (novoType: number) => void;
  aoMudarTextoDeStatus?: (novoStatus: number) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  // Adicionando as opções de status e type
  opcoesDeStatus?: Array<{ value: number; label: string }>;
  opcoesDeType?: Array<{ value: number; label: string }>;
}
export const ToolsListing: React.FC<IToolsListingProps> = ({
  textoDaBusca = '',
  textoDaType,
  textoDaStatus,
  aoMudarTextoDeBusca,
  aoMudarTextoDeType,
  aoMudarTextoDeStatus,
  mostrarInputBusca = false,
  mostrarInputType = false,
  mostrarInputStatus = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  opcoesDeStatus = [], // Definindo um array vazio como padrão
  opcoesDeType = [], // Definindo um array vazio como padrão
}) => {
  const theme = useTheme();

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      {mostrarInputBusca && (
        <TextField
          size="small"
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )}
      {mostrarInputType && (
        <Grid item xs={2}>
          <Select
            size="small"
            value={textoDaType}
            fullWidth
            onChange={(e) => aoMudarTextoDeType?.(Number(e.target.value))}
            displayEmpty
          >
            <MenuItem value="" selected>
              <em>Selecione o Tipo</em>
            </MenuItem>
            {opcoesDeType.map((opcao) => (
              <MenuItem key={opcao.value} value={opcao.value}>
                {opcao.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}

      {mostrarInputStatus && (
        <Grid item xs={2}>
          <Select
            size="small"
            fullWidth
            value={textoDaStatus}
            onChange={(e) => aoMudarTextoDeStatus?.(Number(e.target.value))}
            displayEmpty
          >
            <MenuItem value="" selected>
              <em>Selecione o Status</em>
            </MenuItem>
            {opcoesDeStatus.map((opcao) => (
              <MenuItem key={opcao.value} value={opcao.value}>
                {opcao.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color="primary"
            disableElevation
            variant="contained"
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >
            {textoBotaoNovo}
          </Button>
        )}
      </Box>
    </Box>
  );
};
