
export const Environment = {
  /**
   * Define a quantidade de linhas a ser carregada por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 10,
  /**
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Texto exibido quando nenhum registro é encontrado em uma listagem
   */
  EMPTY_LISTING: 'Nenhum registro encontrado.',
  /**
   * Url base de consultado dos dados dessa aplicação
   */
  URL_BASE: 'https://api.domvision.com.br',
  //URL_BASE: 'http://localhost:8000',
};
