import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { UsersService } from '../../shared/services/api/users/UsersService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VImageField } from '../../shared/forms/VImageField';

interface IFormData {
  email: string;
  priv: number;
  password?: string;
  password_confirmation?: string;
  name: string;
}
const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  name: yup.string().required().min(3),
  email: yup.string().required().min(6).email(),
  password: yup.string().optional().min(6),
  password_confirmation: yup.string().optional().min(6),
  priv: yup.number().required(),
});

export const DetailUsers: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      UsersService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/users');
        } else {
          setName(result.name);
          formRef.current?.setData(result);
        }
      });
    } else {
      formRef.current?.setData({
        setName: '',
        setContact: '',
        setFone: '',
        setDescription: '',
      });
    }
  }, [id]);

  const handleSave = (dados: IFormData) => {
    console.log('dados', dados);
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (!dadosValidados.password) {
          delete dadosValidados.password;
        }

        if (id === 'nova') {
          UsersService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/users');
              } else {
                navigate(`/users/detail/${result}`);
              }
            }
          });
        } else {
          UsersService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              if (isSaveAndClose()) {
                navigate('/users');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      UsersService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/users');
        }
      });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'New User' : name}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Nova"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/users')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/users/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}

            <Grid item>
              <Typography variant="h6">Registro de Usuário</Typography>
            </Grid>

            <Grid
              container
              direction="row"
              padding={2}
              spacing={2}
              justifyContent={'center'}
              alignContent={'center'}
            >
              <Grid item xs={12} md={4}>
                <VImageField name="image" folder="user" imageName={name} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                    <VTextField
                      fullWidth
                      name="name"
                      label="name"
                      disabled={isLoading}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <VTextField
                      fullWidth
                      select
                      name="priv"
                      label="Privilégio"
                      disabled={isLoading}
                    >
                      <MenuItem key="1" value="1">
                        Administrador
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Vendedor/a
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Doutor/a
                      </MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <VTextField
                      fullWidth
                      name="email"
                      label="Email"
                      disabled={isLoading}
                    />
                  </Grid>
                  {id === 'nova' ? (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <VTextField
                          fullWidth
                          type="password"
                          name="password"
                          label="Senha"
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <VTextField
                          fullWidth
                          type="password"
                          name="password_confirmation"
                          label="Confirmação da Senha"
                          disabled={isLoading}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
