import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { SellsService } from '../../shared/services/api/sells/SellsService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { VDateField } from '../../shared/forms/VDateField';
import { AutoCompleteLocation } from '../components/AutoCompleteLocation';
import { ExamDetail } from './components/ExamDetails';
import { AddItem } from './components/addItem/addItem';
import { ItemSell } from './components/ItemSell';
import { IDetailExam } from '../../shared/services/api/exams/ExamsService';
import { AutoCompleteExam } from './components/AutoCompleteExam';
import { TablePaymentSell } from './components/payment/TablePaymentSell';
import { PaymentSell } from './components/payment/PaymentsSell';
import { VImageField } from '../../shared/forms/VImageField';

interface IFormData {
  exam_id: number;
  location_id: number;
  date: Date;
  dateDelivery: Date;
  image?: string;
  status: number;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  exam_id: yup.number().integer().required(),
  location_id: yup.number().integer().required(),
  date: yup.date().required(),
  dateDelivery: yup.date().required(),
  image: yup.string().optional().max(150),
  status: yup.number().required(),
});

export const DetailSells: React.FC = () => {
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [exam_id, setexam_id] = useState<number | undefined>(undefined);
  const [exam, setExam] = useState<IDetailExam | undefined>(undefined);
  const [sell_id, setsell_id] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [remainder, setRemainder] = useState<number>(0);
  const [valuePay, setValuePay] = useState<number>(0);
  const [onAddItem, setOnAdditem] = useState<boolean>(false);
  const [onAddPayment, setOnAddPayment] = useState<boolean>(false);

  const handleExamSelect = (id: number) => {
    setexam_id(id);
  };

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);

      SellsService.getById(Number(id)).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
          navigate('/sells');
        } else {
          formRef.current?.setData(result);
          setexam_id(result.exam_id);
        }
      });
    } else {
      formRef.current?.setData({
        exam_id: '',
        location_id: '',
        date: '',
        dateDelivery: '',
        image: '',
        status: '',
      });
    }
  }, [id, formRef, navigate]);

  useEffect(() => {
    if (onAddItem && onAddPayment) {
      setTotal(0);
      setValuePay(0);
      setRemainder(0);
      if (isSaveAndClose()) {
        navigate('/sells');
      } else {
        navigate(`/sells/detail/${sell_id}`);
      }
    }
  }, [onAddItem, onAddPayment, sell_id]);

  useEffect(() => {
    setRemainder(total - valuePay);
  }, [total, valuePay]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema
      .validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'nova') {
          SellsService.create(dadosValidados).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              setsell_id(Number(result));
            }
          });
        } else {
          SellsService.updateById(Number(id), {
            id: Number(id),
            ...dadosValidados,
          }).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
            } else {
              setsell_id(Number(id));

              if (isSaveAndClose()) {
                navigate('/sells');
              }
            }
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: number) => {
    if (confirm('Realmente deseja apagar?')) {
      SellsService.deleteById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Registro apagado com sucesso!');
          navigate('/sells');
        }
      });
    }
  };

  const handleTotal = (total: number) => {
    setTotal((prevTotal) => prevTotal + total); // Soma o novo total ao valor atual
  };
  const handleValuePay = (valuePay: number) => {
    setValuePay((prevValuePay) => prevValuePay + valuePay); // Soma o novo total ao valor atual
  };
  const handleAddItem = (addItem: boolean) => {
    setOnAdditem(addItem);
  };
  const handleAddPay = (addPayment: boolean) => {
    setOnAddPayment(addPayment); // Soma o novo total ao valor atual
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'nova' ? 'Nova Venda' : 'Detalhes da Venda'}
      barraDeFerramentas={
        <DetailTool
          textoBotaoNovo="Nova"
          mostrarBotaoSalvarEFechar
          mostrarBotaoNovo={id !== 'nova'}
          mostrarBotaoApagar={id !== 'nova'}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/sells')}
          aoClicarEmApagar={() => handleDelete(Number(id))}
          aoClicarEmNovo={() => navigate('/sells/detail/nova')}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6">Registro de Venda</Typography>
            </Grid>
            <Grid
              container
              item
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 6, md: 8 }}
            >
              <Grid item xs={12}>
                <ExamDetail exam_id={exam_id} onExam={setExam} />
              </Grid>
            </Grid>
            <Container>
              <Typography variant="h6" pl={2} gutterBottom>
                Venda
              </Typography>

              <Grid container spacing={3} pl={2}>
                <Grid item xs={12}>
                  <Paper variant="outlined" sx={{ padding: 2 }}>
                    <Grid
                      container
                      direction="row"
                      padding={2}
                      spacing={2}
                      justifyContent={'center'}
                      alignContent={'center'}
                    >
                      <Grid item xs={12} md={4}>
                        <VImageField
                          name="image"
                          folder="sell"
                          imageName={'sell'}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          justifyContent={'center'}
                          alignContent={'center'}
                        >
                          {/* Data da Venda e Data de Entrega */}
                          <Grid item xs={12} md={6}>
                            <VDateField name="date" label="Data da Venda" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <VDateField
                              name="dateDelivery"
                              label="Data de Entrega"
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <VTextField
                              fullWidth
                              select
                              name="status"
                              label="Status"
                              disabled={isLoading}
                            >
                              <MenuItem value={1}>Paga</MenuItem>
                              <MenuItem value={0}>Aberta</MenuItem>
                            </VTextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <AutoCompleteLocation
                              isExternalLoading={isLoading}
                            />
                          </Grid>
                          {/* Exame e Localização */}
                          <Grid item xs={12}>
                            <AutoCompleteExam
                              isExternalLoading={isLoading}
                              onExamSelect={handleExamSelect}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <VTextField
                              fullWidth
                              name="description"
                              label="Descrição"
                              disabled={isLoading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>

            <Grid
              container
              item
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 6, md: 8 }}
            >
              <Grid item xs={12}>
                <ItemSell sell_id={Number(id)} onTotalItem={handleTotal} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 6, md: 8 }}
            >
              <Grid item xs={12}>
                <AddItem
                  sell_id={sell_id}
                  onExam={exam}
                  total={total}
                  onAddItem={handleAddItem} // Passa a função corretamente
                  onTotalAddItem={handleTotal}
                  isExternalLoading={isLoading}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 6, md: 8 }}
            >
              <Grid item xs={12}>
                <PaymentSell
                  total={total}
                  remainder={remainder}
                  valuePay={valuePay}
                  sell_id={sell_id}
                  onValuePayItem={handleValuePay}
                  onAddPayment={handleAddPay}
                />
              </Grid>
              <Grid item xs={12}>
                <TablePaymentSell
                  sell_id={Number(id)}
                  onValuePaySell={handleValuePay}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
