import * as React from 'react';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useField } from '@unform/core';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

type TVTextFieldProps = DatePickerProps<any> & {
  name: string;
};

export const VDateField: React.FC<TVTextFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField, defaultValue } = useField(name);

  const [value, setValue] = useState<Dayjs | null>(
    defaultValue ? dayjs(defaultValue) : null
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        return value ? value.format('YYYY-MM-DD') : ''; // Formata a data para o formato correto
      },
      setValue: (_, value) => setValue(value ? dayjs(value) : null),
    });
  }, [registerField, fieldName, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...rest}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        format="DD/MM/YYYY"
        slotProps={{
          textField: { size: 'small', fullWidth: true },
        }}
      />
    </LocalizationProvider>
  );
};
