import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LensTreatment } from '../../../../components/LensTreatment';
import { LensType } from '../../../../components/LensType';
import { IDetailExam } from '../../../../../shared/services/api/exams/ExamsService';
import { IProductFrame } from '../addItem';
import * as yup from 'yup';
import { ProductsService } from '../../../../../shared/services/api/products/ProductsService';
import { useVForm, VForm } from '../../../../../shared/forms';

interface IProductLensProps {
  isLoading?: boolean;
  onRemove?: number;
  onExam?: IDetailExam;
  onFrame?: IProductFrame;
  sell_id: number;
  onProductSaveComplete?: (product_ids: number[]) => void; // Callback para o componente pai
}

interface ILensTreatment {
  antireflexo: boolean;
  blue: boolean;
  fotossensivel: boolean;
  incolor: boolean;
}

export interface IFormData {
  date: Date;
  description: string;
  status: number;
  type: number;
  priceSell: number;
  priceBuy: number;
  amount: number;

  lensType?: number;
  lensTreatment?: ILensTreatment;
  esf?: number;
  cil?: number;
  eix?: number;
  dnp?: number;
  alt?: number;
  add?: number;

  vertical?: number;
  horizontal?: number;
  bridge?: number;
  largDiagonal?: number;
  uniqueID?: number;
  product_id?: number;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  date: yup.date().required(),
  description: yup.string().required().min(3).max(150),
  status: yup.number().required(),
  type: yup.number().required(),
  priceSell: yup.number().required(),
  priceBuy: yup.number().required(),
  amount: yup.number().required(),
  product_id: yup.number().optional(),
  uniqueID: yup.number().optional(),
  lensType: yup.number().optional(),
  lensTreatment: yup
    .object()
    .shape({
      antireflexo: yup.boolean().required(),
      blue: yup.boolean().required(),
      fotossensivel: yup.boolean().required(),
      incolor: yup.boolean().required(),
    })
    .required(),
  esf: yup.number().optional(),
  cil: yup.number().optional(),
  eix: yup.number().optional(),
  dnp: yup.number().optional(),
  alt: yup.number().optional(),
  add: yup.number().optional(),
  vertical: yup.number().optional(),
  horizontal: yup.number().optional(),
  bridge: yup.number().optional(),
  largDiagonal: yup.number().optional(),
});

export const ProductLens: React.FC<IProductLensProps> = ({
  isLoading = false,
  onExam,
  onFrame,
  onRemove,
  sell_id,
  onProductSaveComplete,
}) => {
  const [exam, setExam] = useState<IDetailExam | undefined>(onExam);
  const [frame, setFrame] = useState<IProductFrame | undefined>(onFrame);
  const [isProductLensComplete, setIsProductLensComplete] = useState(false);
  const [selectedProductList, setSelectedProductList] = useState<IFormData[]>(
    []
  );

  const { formRef } = useVForm();

  useEffect(() => {
    if (onExam) {
      setExam(onExam);
      console.log(onExam);
    }
  }, [onExam]);

  useEffect(() => {
    if (sell_id) {
      if (selectedProductList && selectedProductList.length > 0) {
        handleSave(); // Chama a função de salvamento se houver produtos
      } else if (onProductSaveComplete) {
        // Verifica se a função está definida
        onProductSaveComplete([0]); // Chama a função para indicar que não há produtos
      }
    }
  }, [sell_id, selectedProductList]);

  useEffect(() => {
    if (onFrame) {
      setFrame(onFrame);
      console.log(onFrame);
      formRef.current?.submitForm();
    }
  }, [onFrame]);

  useEffect(() => {
    if (onRemove) {
      handleRemoveProduct(onRemove);
    }
  }, [onRemove]);

  const addProduct = (dados: IFormData) => {
    let productDetail: any = {};
    let lensDataOD: any = {};
    let lensDataOE: any = {};
    let combinedData: any = {};
    let frameData: any = {};

    // Gerar um identificador único para o par de lentes
    const lensID = Date.now();

    productDetail = {
      date: new Date(),
      status: 3, //alocado
      type: 1, // lente
      priceSell: 0,
      priceBuy: 0,
      amount: 1,
      lensTreatment: dados.lensTreatment,
      lensType: dados.lensType,
      uniqueID: onFrame?.uniqueID,
    };
    // Verifica o tipo da lente e ajusta os dados de acordo
    if (dados.lensType === 1 || dados.lensType === 2) {
      // Tipo multifocal
      lensDataOD = {
        esf: exam?.ld?.esf,
        cil: exam?.ld?.cil,
        eix: exam?.ld?.eix,
        dnp: exam?.ld?.dnp,
        alt: exam?.ld?.alt,
        add: exam?.pd?.esf, // Usando o esf de PD (Adição)
      };

      lensDataOE = {
        esf: exam?.le?.esf,
        cil: exam?.le?.cil,
        eix: exam?.le?.eix,
        dnp: exam?.le?.dnp,
        alt: exam?.le?.alt,
        add: exam?.pe?.esf, // Usando o esf de PE (Adição)
      };

      frameData = {
        vertical: onFrame?.vertical,
        horizontal: onFrame?.horizontal,
        bridge: onFrame?.bridge,
        largDiagonal: onFrame?.largDiagonal,
      };
    }
    //  lensType 3  = Perto
    if (dados.lensType === 3) {
      lensDataOD = exam?.pd;
      lensDataOE = exam?.pe;
    }
    //  lensType 4  = Longe
    if (dados.lensType === 4) {
      lensDataOD = exam?.ld;
      lensDataOE = exam?.le;
    }

    // Combine os dados de lente e armação para ambos os olhos
    if (dados.lensType === 1 || dados.lensType === 2) {
      combinedData = [
        { ...productDetail, ...lensDataOD, ...frameData },
        { ...productDetail, ...lensDataOE, ...frameData },
      ];
    }
    if (dados.lensType === 3 || dados.lensType === 4) {
      combinedData = [
        { ...productDetail, ...lensDataOD },
        { ...productDetail, ...lensDataOE },
      ];
    }
    handleAddItem(combinedData);
  };

  const handleAddItem = (combinedData: IFormData[]) => {
    setSelectedProductList((prevList) => [...prevList, ...combinedData]);
  };

  const handleRemoveProduct = (uniqueID: number) => {
    setSelectedProductList((prevItems) => {
      const updatedList = prevItems.filter(
        (product) => product.uniqueID !== uniqueID
      );
      return updatedList;
    });
  };

  const handleSave = () => {
    const validPromises = selectedProductList.map((item) => {
      // Adiciona uma descrição com o sell_id para cada item
      const itemWithDescription = {
        ...item,
        description: ` exame:${exam?.id}, ${exam?.patient?.name}, ${exam?.date}, vendaID: ${sell_id}`,
      };

      // Desestrutura o objeto para remover o campo uniqueID
      const { uniqueID, ...itemWithoutUniqueID } = itemWithDescription;
      // Valida o item sem o campo uniqueID
      return formValidationSchema.validate(itemWithoutUniqueID, {
        abortEarly: false,
      });
    });

    // Um array temporário para armazenar os IDs dos produtos
    const tempproduct_ids: number[] = [];

    Promise.all(validPromises)
      .then((validatedItems) => {
        // Cria cada produto de forma assíncrona

        const savePromises = validatedItems.map((item) =>
          ProductsService.create(item).then((result) => {
            if (result instanceof Error) {
              alert(result.message);
            } else {
              console.log('Produto salvo com sucesso:', result);
              tempproduct_ids.push(result); // Supondo que o ID gerado esteja em result.id
            }
          })
        );

        // Após salvar todos os produtos, atualiza o estado e passa para o componente pai
        Promise.all(savePromises).then(() => {
          // Se o callback for passado, envia os IDs ao componente pai
          if (onProductSaveComplete) {
            onProductSaveComplete(tempproduct_ids);
            setSelectedProductList([]);
          }
        });
      })
      .catch((errors: yup.ValidationError) => {
        console.log('Erros de validação:', errors.inner);
      });
  };
  return (
    <VForm ref={formRef} onSubmit={addProduct}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={5}>
          <LensTreatment isExternalLoading={isLoading} />
        </Grid>
        <Grid item xs={2}>
          <LensType isExternalLoading={isLoading} />
        </Grid>
      </Grid>
    </VForm>
  );
};
