import { useEffect, useState } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useField } from '@unform/core';
import { VCheckbox } from '../../../shared/forms/VCheckbox';

interface IDiagnosis {
  miopia: boolean;
  hipermetropia: boolean;
  astigmatismo: boolean;
  presbiopia: boolean;
}

interface IDiagnosisProps {
  isExternalLoading?: boolean;
}

export const Diagnosis: React.FC<IDiagnosisProps> = ({
  isExternalLoading = false,
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField('diagnosis');

  const [diagnosis, setDiagnosis] = useState<IDiagnosis>(
    defaultValue || {
      miopia: false,
      hipermetropia: false,
      astigmatismo: false,
      presbiopia: false,
    }
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => diagnosis,
      setValue: (_, newDiagnosis) => setDiagnosis(newDiagnosis),
    });
  }, [registerField, fieldName, diagnosis]);

  const handleCheckboxChange = (name: keyof IDiagnosis, checked: boolean) => {
    const updatedDiagnosis = { ...diagnosis, [name]: checked };
    setDiagnosis(updatedDiagnosis);
    clearError(); // Limpa o erro ao modificar qualquer checkbox
  };

  return (
    <>
      <Typography variant="subtitle2">Diagnóstico</Typography>

      <Grid container spacing={2}>
        <Grid item>
          <VCheckbox
            name="diagnosis.miopia"
            label="Miopia"
            checked={diagnosis.miopia}
            onChange={(_, checked) => handleCheckboxChange('miopia', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="diagnosis.hipermetropia"
            label="Hipermetropia"
            checked={diagnosis.hipermetropia}
            onChange={(_, checked) =>
              handleCheckboxChange('hipermetropia', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="diagnosis.astigmatismo"
            label="Astigmatismo"
            checked={diagnosis.astigmatismo}
            onChange={(_, checked) =>
              handleCheckboxChange('astigmatismo', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="diagnosis.presbiopia"
            label="Presbiopia"
            checked={diagnosis.presbiopia}
            onChange={(_, checked) =>
              handleCheckboxChange('presbiopia', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        {isExternalLoading && (
          <Grid item>
            <CircularProgress size={28} />
          </Grid>
        )}
      </Grid>

      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
    </>
  );
};
