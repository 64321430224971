import { useEffect, useState } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useField } from '@unform/core';
import { VCheckbox } from '../../shared/forms/VCheckbox';

export interface ILensTreatment {
  antireflexo: boolean;
  blue: boolean;
  fotossensivel: boolean;
  incolor: boolean;
}

interface ILensTreatmentProps {
  isExternalLoading?: boolean;
  onLensTreatmentChange?: (lensTreatmentId: ILensTreatment | undefined) => void; // Callback para mudar o tratamento da lente
}

export const LensTreatment: React.FC<ILensTreatmentProps> = ({
  isExternalLoading = false,
  onLensTreatmentChange,
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField('lensTreatment');

  const [lensTreatment, setLensTreatment] = useState<ILensTreatment>(
    defaultValue || {
      antireflexo: false,
      blue: false,
      fotossensivel: false,
      incolor: false,
    }
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => lensTreatment,
      setValue: (_, newLensTreatment) => {
        setLensTreatment(newLensTreatment);
        if (onLensTreatmentChange != undefined) {
          console.log('lensTreatment', newLensTreatment);
          onLensTreatmentChange(newLensTreatment); // Chama a função de callback ao mudar o tipo de lente
        }
      },
    });
  }, [registerField, fieldName, lensTreatment, onLensTreatmentChange]);

  const handleCheckboxChange = (
    name: keyof ILensTreatment,
    checked: boolean
  ) => {
    const updatedLensTreatment = { ...lensTreatment, [name]: checked };
    setLensTreatment(updatedLensTreatment);
    clearError(); // Limpa o erro ao modificar qualquer checkbox
  };

  return (
    <>
      <Typography variant="subtitle2">Tratamento</Typography>

      <Grid container spacing={2}>
        <Grid item>
          <VCheckbox
            name="lensTreatment.antireflexo"
            label="Antireflexo"
            checked={
              lensTreatment === undefined ? false : lensTreatment.antireflexo
            }
            onChange={(_, checked) =>
              handleCheckboxChange('antireflexo', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="lensTreatment.blue"
            label="Blue"
            checked={lensTreatment === undefined ? false : lensTreatment.blue}
            onChange={(_, checked) => handleCheckboxChange('blue', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="lensTreatment.fotossensivel"
            label="Fotossensivel"
            checked={
              lensTreatment === undefined ? false : lensTreatment.fotossensivel
            }
            onChange={(_, checked) =>
              handleCheckboxChange('fotossensivel', checked)
            }
            disabled={isExternalLoading}
          />
        </Grid>

        <Grid item>
          <VCheckbox
            name="lensTreatment.incolor"
            label="Incolor"
            checked={
              lensTreatment === undefined ? false : lensTreatment.incolor
            }
            onChange={(_, checked) => handleCheckboxChange('incolor', checked)}
            disabled={isExternalLoading}
          />
        </Grid>

        {isExternalLoading && (
          <Grid item>
            <CircularProgress size={28} />
          </Grid>
        )}
      </Grid>

      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
    </>
  );
};
