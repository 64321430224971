import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';
import { IListLocation } from '../locations/LocationsService';

export interface IIllness {
  estrabismo: boolean;
  catarata: boolean;
  glaucoma: boolean;
  ceratocone: boolean;
  diabete: boolean;
}

export interface IListPatient {
  id: number;
  address: string;
  date: Date;
  description: string;
  fone: string;
  illness: IIllness;
  image?: string;
  location_id: number;
  location?: IListLocation;
  name: string;
}

export interface IDetailPatient {
  id: number;
  address: string;
  date: Date;
  description: string;
  fone: string;
  illness: IIllness;
  image?: string;
  location_id: number;
  name: string;
}

type TPatientTotalCount = {
  data: IListPatient[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  id = 0
): Promise<TPatientTotalCount | Error> => {
  try {
    let urlRelativa = '';

    urlRelativa = `/patients?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&id=${id}`;

    const { data } = await Api().get(urlRelativa);

    if (data) {
      return {
        data: data.data, // Aqui você acessa o array de dados diretamente
        totalCount: data.total, // Pegando o total de registros da resposta
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailPatient | Error> => {
  try {
    const { data } = await Api().get(`/patients/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailPatient, 'id'>
): Promise<number | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');

    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;

    if (dados.image && !dados.image.startsWith('http')) {
      imageUrl = await handleImageUpload(dados.image, 'patient');
    }

    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    }; // Adiciona a imagem nos dadosmrq
    const { data } = await Api().post('/patients', dadosComUserID);
    if (data && data.patient.id) {
      return data.patient.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: IDetailPatient
): Promise<void | Error> => {
  try {
    const storedUserID = localStorage.getItem('USER_ID');
    if (!storedUserID) {
      return new Error('UserID não encontrado no localStorage.');
    }

    let imageUrl: string | undefined;
    if (dados.image && !dados.image.startsWith('http')) {
      // Chame a função de upload e capture a URL ou nome retornado
      imageUrl = await handleImageUpload(dados.image, 'patient'); // Agora retorna a URL da imagem
      console.log('imageUrl', imageUrl);
    }
    const dadosComUserID = {
      ...dados,
      user_id: Number(storedUserID),
      image: imageUrl,
      date:
        dados.date instanceof Date
          ? dados.date.toISOString().split('T')[0] // Formata para 'YYYY-MM-DD'
          : dados.date, // Caso a data já esteja formatada
    };
    await Api().put(`/patients/${id}`, dadosComUserID);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/patients/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const PatientsService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
