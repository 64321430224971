import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
//import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '../../../shared/hooks';
import {
  DashboardsService,
  IListTableLocationSell,
} from '../../../shared/services/api/dashboars/DashboardsService';
import { TableSell } from './location/TableSell';
import { TableProfit } from './location/TableProfit';
import { ChartProfit } from './location/ChartProfit';
import { ChartSell } from './location/ChartSell';

export const LocationDashboard: React.FC = () => {
  //const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();

  const [rows, setRows] = useState<IListTableLocationSell[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const search = '';

  /*   const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]); */

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      DashboardsService.getlocationDashboard(0, search).then((result) => {
        setIsLoading(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [search]);

  return (
    <Grid item container spacing={2}>
      <Grid item md={12} lg={7}>
        <TableSell
          data={rows}
          totalCount={totalCount}
          isExternalLoading={isLoading}
        />
      </Grid>
      <Grid item md={12} lg={5}>
        <TableProfit
          data={rows}
          totalCount={totalCount}
          isExternalLoading={isLoading}
        />
      </Grid>
      <Grid item md={12} lg={6}>
        <ChartProfit
          data={rows}
          totalCount={totalCount}
          isExternalLoading={isLoading}
        />
      </Grid>
      <Grid item md={12} lg={6}>
        <ChartSell
          data={rows}
          totalCount={totalCount}
          isExternalLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
