import { useEffect, useState } from 'react';
import { Box, Card, CardMedia, Grid, Paper, Typography } from '@mui/material';
import {
  IListPatient,
  PatientsService,
} from '../../../shared/services/api/patients/PatientsService';
import { getImage } from '../../../shared/services/api/image/imageService';

interface IPatientDetailsProps {
  patient_id?: number; // Torne o patient_id opcional se não houver um ID selecionado
}

export const PatientDetails: React.FC<IPatientDetailsProps> = ({
  patient_id,
}) => {
  //const [patient, setPatient] = useState<any>(null); // Armazena os dados do paciente
  const [patient, setPatient] = useState<IListPatient>();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    if (patient_id) {
      setIsLoading(true);

      PatientsService.getById(patient_id).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          setIsLoading(true);
          if (result.image && result.image !== 'null') {
            getImage(result.image, 'patient').then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                console.error(result.message);
              } else {
                setImage(result);
              }
            });
          } else {
            setIsLoading(false);
          }
          setPatient(result); // Armazena o paciente encontrado
        }
      });
    }
  }, [patient_id]);

  return (
    <>
      {isLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        patient && (
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid
              container
              direction="row"
              padding={2}
              spacing={2}
              justifyContent={'center'}
              alignContent={'center'}
            >
              <Grid item xs={6} md={2}>
                <Card sx={{ maxWidth: 240 }}>
                  <CardMedia
                    component="img"
                    height="240"
                    image={image}
                    alt="patient"
                  />
                </Card>
              </Grid>
              <Grid item xs={6} md={5}>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignContent={'center'}
                >
                  <Typography variant="h6">Nome: {patient.name}</Typography>
                  <Typography variant="body1">
                    Data: {new Date(patient.date).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Typography variant="body1">
                  Endereço: {patient.address}
                </Typography>
                <Typography variant="body1">
                  Telefone: {patient.fone}
                </Typography>
                <Typography variant="body1">
                  Descrição: {patient.description}
                </Typography>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '4fr 4fr',
                    gap: 1,
                  }}
                  pt={1}
                >
                  {Object.entries(patient.illness).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: value ? 'success.main' : 'error.main',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {value ? '✔️' : '❌'}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )
      )}
    </>
  );
};
