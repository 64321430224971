import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import {
  ExamsService,
  IDetailExam,
} from '../../../shared/services/api/exams/ExamsService';
import PrescriptionTable from './prescritionTable';
import { getImage } from '../../../shared/services/api/image/imageService';

interface IExamDetailProps {
  exam_id?: number; // Torne o exam_id opcional se não houver um ID selecionado
  onExam: (exam: IDetailExam) => void;
}

export const ExamDetail: React.FC<IExamDetailProps> = ({ exam_id, onExam }) => {
  const [exam, setExam] = useState<IDetailExam | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');


  useEffect(() => {
    if (exam_id) {
      setIsLoading(true);
      ExamsService.getById(exam_id).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          setIsLoading(true);
          if (result.patient?.image && result.patient?.image !== 'null') {
            getImage(result.patient?.image, 'sell').then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                console.error(result.message);
              } else {
                setImage(result);
              }
            });
          } else {
            setIsLoading(false);
          }
          setExam(result); // Armazena o exame encontrado
          onExam(result);
        }
      });
    }
  }, [exam_id]);

  return (
    <Container>
      {isLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        exam && (
          <>
            <Typography variant="h6" pl={2} gutterBottom>
              Exame
            </Typography>
            <Card sx={{ display: 'flex' }}>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={image}
                alt={exam.patientImage}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography component="div" variant="h6">
                      {exam.id} - {exam.patientName}
                    </Typography>
                    <Typography component="div" variant="subtitle1">
                      {new Date(exam.date).toLocaleDateString('pt-BR')}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                  >
                    Médico: {exam.doctor}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                  >
                    {exam.description}
                  </Typography>
                  {/* Exibir Diagnóstico */}
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ mt: 2 }}
                  >
                    Diagnóstico:
                  </Typography>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: 2,
                    }}
                  >
                    {Object.entries(exam.diagnosis).map(([key, value]) => (
                      <Box
                        key={key}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography variant="body2" sx={{ flexGrow: 1 }}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: value ? 'success.main' : 'error.main',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {value ? '✔️' : '❌'}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Box>
            </Card>

            <Paper variant="outlined">
              <Typography variant="body1">
                <PrescriptionTable
                  ld={exam.ld}
                  le={exam.le}
                  pd={exam.pd}
                  pe={exam.pe}
                />
              </Typography>
            </Paper>
          </>
        )
      )}
    </Container>
  );
};
