import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Paper, LinearProgress, Typography } from '@mui/material';
import { IListTableLocationSell } from '../../../../shared/services/api/dashboars/DashboardsService';
import { Environment } from '../../../../shared/environment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IChartProfitProps {
  isExternalLoading?: boolean;
  data?: IListTableLocationSell[];
  totalCount: number;
}

export const ChartProfit: React.FC<IChartProfitProps> = ({
  isExternalLoading = false,
  data,
  totalCount,
}) => {
  const [chartData, setChartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      const labels = data.map((row) => row.local);
      const totalExpenseData = data.map((row) => row.totalExpense || 0);
      const grossProfitData = data.map((row) => row.grossProfit || 0);
      const netProfitData = data.map((row) => row.netProfit || 0);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Despesas',
            data: totalExpenseData,
            backgroundColor: 'rgba(237, 213, 78)',
          },
          {
            label: 'Lucro Bruto',
            data: grossProfitData,
            backgroundColor: 'rgba(89, 78, 237)',
          },
          {
            label: 'Lucro Líquido',
            data: netProfitData,
            backgroundColor: 'rgba(91, 237, 78)',
          },
        ],
      });
    }
  }, [data]);

  return (
    <Paper variant="outlined" sx={{ m: 1, p: 2, width: 'auto' }}>
      <Typography variant="h5" component="div" gutterBottom>
        Lucro e Despesas por Local
      </Typography>

      {loading && <LinearProgress variant="indeterminate" />}

      {chartData && (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Lucro e Despesas por Local',
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return `R$ ${value.toLocaleString('pt-BR')}`;
                  },
                },
              },
            },
          }}
        />
      )}

      {!chartData && totalCount === 0 && !isExternalLoading && (
        <Typography variant="body1" color="textSecondary">
          {Environment.EMPTY_LISTING}
        </Typography>
      )}

      {loading && (
        <Typography variant="body2" color="textSecondary">
          Carregando dados...
        </Typography>
      )}
    </Paper>
  );
};
