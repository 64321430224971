import { AxiosError } from 'axios';

import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { handleImageUpload } from '../image/imageService';

export interface IListUser {
  id: number;
  name: string;
  email: string;
  priv: number;
}

export interface IDetailUser {
  id: number;
  email: string;
  priv: number;
  password?: string;
  password_confirmation?: string;
  image?: string;
  name: string;
}

type TUserTotalCount = {
  data: IListUser[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = '',
  id = 0
): Promise<TUserTotalCount | Error> => {
  try {
    const urlRelativa = `/users?filter=${filter}&id=${id}`;

    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao listar os registros.'
    );
  }
};

const getById = async (id: number): Promise<IDetailUser | Error> => {
  try {
    const { data } = await Api().get(`/users/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetailUser, 'id'>
): Promise<number | Error> => {
  try {
    let imageUrl: string | undefined;

    if (dados.image && !dados.image.startsWith('http')) {
      // Chame a função de upload e capture a URL ou nome retornado
      imageUrl = await handleImageUpload(dados.image, 'user'); // Agora retorna a URL da imagem
    }

    const dadosComImage = {
      ...dados,
      image: imageUrl,
    };

    const { data } = await Api().post('/users', dadosComImage);

    if (data && data.user.id) {
      return data.user.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: number,
  dados: Omit<IDetailUser, 'password'>
): Promise<void | Error> => {
  try {
    let imageUrl: string | undefined;
    if (dados.image && !dados.image.startsWith('http')) {
     
      // Chame a função de upload e capture a URL ou nome retornado
      imageUrl = await handleImageUpload(dados.image, 'user'); // Agora retorna a URL da imagem
    }

    const dadosComImage = {
      ...dados,
      image: imageUrl,
    };
    console.log('dadosComImage', dadosComImage);

    await Api().put(`/users/${id}`, dadosComImage);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao atualizar o registro.'
    );
  }
};

const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api().delete(`/users/${id}`);
  } catch (error) {
    return new Error(
      (error as AxiosError).response?.data.errors.default ||
        'Erro ao apagar o registro.'
    );
  }
};

export const UsersService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
};
