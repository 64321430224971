import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Grid,
  Typography,
  Card,
  Box,
  CardContent,
  CardMedia,
} from '@mui/material';
import { ProductsService } from '../../../../../shared/services/api/products/ProductsService';
import { useDebounce } from '../../../../../shared/hooks';
import { Environment } from '../../../../../shared/environment';
import { getImage } from '../../../../../shared/services/api/image/imageService';

type TAutoCompleteProductOption = {
  id: number;
  label: string;
};

interface IAutoCompleteProductProps {
  isExternalLoading?: boolean;
  onProductSelect: (product: IProduct) => void;
}

interface IProduct {
  id: number;
  image?: string;
  description: string;
  type: number;
  priceSell: number;
  priceBuy: number;
}

export const AutoCompleteProduct: React.FC<IAutoCompleteProductProps> = ({
  isExternalLoading = false,
  onProductSelect,
}) => {
  const { debounce } = useDebounce();
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [product, setProduct] = useState<IProduct | null>(null);
  const [opcoes, setOpcoes] = useState<TAutoCompleteProductOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');
  const [image, setImage] = useState('');

  // Atualiza o produto com base no selectedId
  useEffect(() => {
    if (selectedId) {
      setIsLoading(true);
      ProductsService.getById(selectedId).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          setIsLoading(true);
          if (result.image && result.image !== 'null') {
            getImage(result.image, 'product').then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                console.error(result.message);
              } else {
                setImage(result);
              }
            });
          } else {
            setIsLoading(false);
          }
          setProduct(result);
          onProductSelect(result); // Envia o produto selecionado para o componente pai
        }
      });
    } else {
      setProduct(null);
    }
  }, [selectedId]);

  // Atualiza a lista de opções de produtos com debounce
  useEffect(() => {
    debounce(() => {
      ProductsService.getAll(1, '',2, selectedId).then((result) => {
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          setOpcoes(
            result.data.map((product) => ({
              id: product.id,
              label: product.description,
            }))
          );
        }
      });
    });
  }, [busca, debounce, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    return opcoes.find((opcao) => opcao.id === selectedId) || null;
  }, [selectedId, opcoes]);

  return (
    <Grid >
      <Autocomplete
        options={opcoes}
        loading={isLoading}
        disabled={isExternalLoading}
        style={{ width: '100%' }}
        value={autoCompleteSelectedOption}
        onInputChange={(_, newValue) => setBusca(newValue)}
        onChange={(_, newValue) => {
          if (newValue?.id !== selectedId) {
            setSelectedId(newValue?.id);
            setBusca('');
          }
        }}
        popupIcon={isLoading ? <CircularProgress size={28} /> : undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Produto"
            variant="outlined"
            size="small"
          />
        )}
      />

      {product && (
        <Grid item alignItems="center" columnSpacing={2} rowSpacing={4}>
          <Card sx={{ display: 'flex' }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={image}
              alt={product.image}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="subtitle1">
                  {product.description}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ color: 'text.secondary' }}
                >
                  Tipo: 
                  {product.type === 1
                    ? 'Lente'
                    : product.type === 2
                      ? 'Armação'
                      : 'Outro'}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ color: 'text.secondary' }}
                >
                  Preço de Venda: R$ {product.priceSell.toFixed(2)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  sx={{ color: 'text.secondary' }}
                >
                  Preço de Compra: R$ {product.priceBuy.toFixed(2)}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
