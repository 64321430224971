import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { ToolsListing } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { PatientsService } from '../../shared/services/api/patients/PatientsService';
import { LocationsService } from '../../shared/services/api/locations/LocationsService';
import { ExamsService } from '../../shared/services/api/exams/ExamsService';
import { LocationDashboard } from './components/LocationDashboard';

export const Dashboard = () => {
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [totalCountPatients, setTotalCountPatients] = useState(0);

  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [totalCountLocations, setTotalCountLocations] = useState(0);

  const [isLoadingExams, setIsLoadingExams] = useState(true);
  const [totalCountExams, setTotalCountExams] = useState(0);

  useEffect(() => {
    setIsLoadingLocations(true);
    setIsLoadingPatients(true);

    PatientsService.getAll(1).then((result) => {
      setIsLoadingPatients(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountPatients(result.totalCount);
      }
    });
    LocationsService.getAll(1).then((result) => {
      setIsLoadingLocations(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountLocations(result.totalCount);
      }
    });
    ExamsService.getAll(1).then((result) => {
      setIsLoadingExams(false);

      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCountExams(result.totalCount);
      }
    });
  }, []);

  return (
    <LayoutBaseDePagina
      titulo="Página inicial"
      barraDeFerramentas={<ToolsListing mostrarBotaoNovo={false} />}
    >
      <Box width="100%" display="flex">
        <Grid container margin={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Pacientes:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingPatients && (
                      <Typography variant="h4">{totalCountPatients}</Typography>
                    )}
                    {isLoadingPatients && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Locais:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingLocations && (
                      <Typography variant="h4">
                        {totalCountLocations}
                      </Typography>
                    )}
                    {isLoadingLocations && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" align="left">
                    Exames:
                  </Typography>

                  <Box
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isLoadingExams && (
                      <Typography variant="h4">{totalCountExams}</Typography>
                    )}
                    {isLoadingExams && (
                      <Typography variant="h6">Carregando...</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <LocationDashboard />
            </Grid>
         
          </Grid>
        </Grid>
      </Box>
    </LayoutBaseDePagina>
  );
};
