import * as React from 'react';
import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import axios from 'axios';
import { Environment } from '../environment';

// Importando imagens padrão
import defaultPatientImage from '../assets/images/patient.png';
import defaultExamImage from '../assets/images/exam.png';
import defaultProductImage from '../assets/images/product.png';
import defaultSellImage from '../assets/images/sell.png';
import defaultUserImage from '../assets/images/user.png';

type TVImageFieldProps = {
  name: string;
  folder: 'patient' | 'exam' | 'product' | 'sell' | 'user'; // Definindo as opções de pasta
  imageName: string;
};

const defaultImageMap = {
  patient: defaultPatientImage,
  exam: defaultExamImage,
  product: defaultProductImage,
  sell: defaultSellImage,
  user: defaultUserImage,
};

export const VImageField: React.FC<TVImageFieldProps> = ({
  name,
  folder,
  imageName,
}) => {
  const serverURL = `${Environment.URL_BASE}/api/images/${folder}`;
  const defaultImage = defaultImageMap[folder];

  const { fieldName, registerField } = useField(name);
  const [value, setValue] = useState<string>(defaultImage); // Inicializa com a imagem padrão
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageAlter, setImageAlter] = useState(false);
  const token = localStorage.getItem('APP_ACCESS_TOKEN') || '""';
  const [originalImageName, setOriginalImageName] = useState(imageName);

  // Função para buscar a imagem com token
  const fetchImageWithToken = async (imageName: string) => {
    try {
      const response = await axios.get(`${serverURL}/${imageName}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
        responseType: 'blob',
      });
      const imageURL = URL.createObjectURL(response.data);
      setValue(imageURL); // Define a URL como o valor do campo
      setImageLoaded(true); // Indica que a imagem foi carregada
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
      // Se houver erro, mantém o valor atual
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => {
        return imageAlter === true ? value : originalImageName;
      },
      setValue: (_, newValue) => {
        if (newValue && newValue !== null) {
          fetchImageWithToken(newValue);
        } else {
          setValue(defaultImage); // Se não houver novo valor, define a imagem padrão
          setImageLoaded(true);
        }
      },
    });
  }, [registerField, fieldName, value, defaultImage]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setValue(URL.createObjectURL(file)); // Armazena a URL temporária da imagem
      setImageLoaded(true); // Define que a imagem foi carregada
      setImageAlter(true);
    } else {
      setValue(defaultImage); // Se não houver arquivo, define a imagem padrão
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <Button size="small" color="primary" component="label">
        Escolher Imagem
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleFileChange}
        />
      </Button>

      {imageLoaded ? (
        <CardMedia
          component="img"
          height="300"
          image={value}
          alt="Imagem selecionada"
        />
      ) : (
        <Box
          sx={{
            height: 140,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          <Typography variant="caption" color="textSecondary">
            Nenhuma imagem selecionada
          </Typography>
        </Box>
      )}
    </Card>
  );
};
