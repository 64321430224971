import { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  CheckboxProps,
} from '@mui/material';
import { useField } from '@unform/core';

type TVCheckboxProps = Omit<FormControlLabelProps, 'control'> & {
  name: string;
  label: string;
  checkboxProps?: CheckboxProps;
  checked: boolean; // Importante garantir que o checked venha do componente pai
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export const VCheckbox: React.FC<TVCheckboxProps> = ({
  name,
  label,
  checkboxProps,
  checked = false,
  onChange,
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [internalChecked, setInternalChecked] = useState<boolean>(checked);

  useEffect(() => {
    setInternalChecked(checked); // Sempre sincroniza com o prop 'checked' vindo do pai
  }, [checked]);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => internalChecked,
      setValue: (_, newValue) => setInternalChecked(!!newValue),
      clearValue: () => setInternalChecked(false),
    });
  }, [registerField, fieldName, internalChecked]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newChecked: boolean
  ) => {
    setInternalChecked(newChecked); // Atualiza o estado interno
    onChange(event, newChecked); // Propaga a mudança para o pai
    checkboxProps?.onChange?.(event, newChecked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          {...checkboxProps}
          checked={internalChecked} // Usa o estado controlado
          onChange={handleChange}
          onClick={(e) => {
            if (error) clearError(); // Limpa erros se houver
            checkboxProps?.onClick?.(e);
          }}
        />
      }
      label={label}
      sx={{
        display: 'flex',
        alignItems: 'center', // Alinha o label e o checkbox verticalmente
        '& .MuiFormControlLabel-label': {
          fontSize: '14px', // Altere o tamanho da label
        },
      }}
      {...rest}
    />
  );
};
