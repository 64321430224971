import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useField } from '@unform/core';

interface IDegreeProps {
  isExternalLoading?: boolean;
  name: string;
}

interface IDegree {
  esf: number;
  cil: number;
  eix: number;
  dnp: number;
  alt: number;
}

export const Degree: React.FC<IDegreeProps> = ({
  isExternalLoading = false,
  name,
}) => {
  const { fieldName, registerField, defaultValue } = useField(name);

  // Inicializar o estado com valores numéricos
  const [degree, setDegree] = useState<IDegree>({
    esf: defaultValue?.esf || 0,
    cil: defaultValue?.cil || 0,
    eix: defaultValue?.eix || 0,
    dnp: defaultValue?.dnp || 0,
    alt: defaultValue?.alt || 0,
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: degree,
      getValue: () => degree, // Retorna o objeto degree diretamente
      setValue: (ref, value: IDegree) => {
        setDegree({
          esf: value?.esf || 0,
          cil: value?.cil || 0,
          eix: value?.eix || 0,
          dnp: value?.dnp || 0,
          alt: value?.alt || 0,
        });
      },
      clearValue: () => setDegree({ esf: 0, cil: 0, eix: 0, dnp: 0, alt: 0 }),
    });
  }, [fieldName, registerField, degree]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDegree((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0, // Converter o valor para número
    }));
  };

  return (
    <Grid container item direction="row" >
      {isExternalLoading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        <>
          <Grid item xs={2}>
            <TextField
              label="Esf."
              name="esf"
              size="small"
              value={degree.esf}
              onChange={handleChange}
              type='number'
              inputProps={{ step: 0.25 }} // Define o incremento de 0,25 
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Cil."
              name="cil"
              size="small"
              value={degree.cil}
              onChange={handleChange}
              type='number'
              inputProps={{ step: 0.25 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Eixo"
              name="eix"
              size="small"
              value={degree.eix}
              onChange={handleChange}
              type='number'
              inputProps={{ step: 30 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              label="DNP"
              name="dnp"
              size="small"
              value={degree.dnp}
              onChange={handleChange}
              type='number'
              inputProps={{ step: 10 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              label="ALT"
              name="alt"
              size="small"
              value={degree.alt}
              onChange={handleChange}
              type='number'
              inputProps={{ step: 10 }}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
