import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useField } from '@unform/core';

type TLensTypeOption = {
  id: number;
  label: string;
};

interface ILensTypeProps {
  isExternalLoading?: boolean;
  onLensTypeChange?: (lensTypeId: number | undefined) => void; // Callback para mudar o tipo de lente
}

export const LensType: React.FC<ILensTypeProps> = ({
  isExternalLoading = false,
  onLensTypeChange, // Recebendo a função de callback
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField('lensType');

  const [selectedId, setSelectedId] = useState<number | undefined>(
    defaultValue
  );
  const [opcoes, setOpcoes] = useState<TLensTypeOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => {
        setSelectedId(newSelectedId);
        if (onLensTypeChange) {
          onLensTypeChange(newSelectedId); // Chama a função de callback ao mudar o tipo de lente
          console.log(newSelectedId);
        }
      },
    });
  }, [registerField, fieldName, selectedId, onLensTypeChange]);

  useEffect(() => {
    setIsLoading(true);
    setOpcoes([
      { id: 1, label: 'Multifocal' },
      { id: 2, label: 'Bifocal' },
      { id: 3, label: 'Perto' },
      { id: 4, label: 'Longe' },
    ]);
    setIsLoading(false);
  }, []);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    return opcoes.find((opcao) => opcao.id === selectedId) || null;
  }, [selectedId, opcoes]);

  return (
    <>
      <Autocomplete
        openText="Abrir"
        closeText="Fechar"
        noOptionsText="Sem opções"
        loadingText="Carregando..."
        disablePortal
        options={opcoes}
        loading={isLoading}
        disabled={isExternalLoading}
        value={autoCompleteSelectedOption}
        style={{ width: '100%' }}
        onChange={(_, newValue) => {
          setSelectedId(newValue?.id);
          clearError();
        }}
        popupIcon={
          isExternalLoading || isLoading ? (
            <CircularProgress size={28} />
          ) : undefined
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tipo de Lente"
            size="small"
            error={!!error}
            helperText={error}
          />
        )}
      />
    </>
  );
};
